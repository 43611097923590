body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.icon-colapse_down {
  transform: rotate(90deg);
}

.rotate {
  -webkit-animation: rotate 0.5s normal linear;
  animation: rotate 0.5s normal linear;
  transform: rotate(180deg);
}

.hr-theme-slash-2 {
  display: flex;
}

.hr-theme-slash-2 > .hr-line {
  width: 25%;
  position: relative;
  margin: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.hr-theme-slash-2 > .hr-icon {
  position: relative;
  top: -3px;
  color: #32a852;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
  /* 0% {
    -webkit-transform: rotate(0, 0, 1, 0deg);
    transform: rotate(0deg);
  } */
  /* 25% {
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 30deg);
  }
  50% {
    -webkit-transform: rotate3d(0, 0, 1, 180deg);
    transform: rotate3d(0, 0, 1, 60deg);
  }
  75% {
    -webkit-transform: rotate3d(0, 0, 1, 270deg);
    transform: rotate3d(0, 0, 1, 75deg);
  } */
  /* 100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  } */
}

