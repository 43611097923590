
body {
    width: 100vw;
    height: 100vh;
    margin: 0;
    overflow: hidden;
}
.curved {
    background: #4287f5;
    color: #fff;
    text-align: center;
    
}

.curved h1 {
    font-size: 2rem;
    margin-top: 0;
    padding: 20px;
}
.curved img {
    margin-top: 0;

}


.curved svg {
    display: block;
}

.curvedBottom {
    background: #4287f5;
    height: 100vh;
}

.main-form-container {
    position: absolute; 
    top: 42%; 
    left: 50%; 
    width: 360px; 
    transform: translate(-50%, -50%); 
    -moz-transform: translate(-50%, -50%); 
    -webkit-transform: translate(-50%, -50%); 
    -o-transform: translate(-50%, -50%); 
    -ms-transform: translate(-50%, -50%);
    padding-bottom: 50px;       
    margin-top: 30px;
}